import React, { useRef } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Hero from "../components/hero";
import AreaMap from "../components/areaMap";
import Layout from "../components/layout";
import ContactForm from "../components/contactForm";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import ContactPageSchema from "../components/Schema/contactPage";
import CallIcon from "../components/call-icon";
import { checkPropertiesForNull } from "../utils/object";
import { SafeHtmlParser } from "../components/safe-html-parser";
import { GatsbyImage } from "gatsby-plugin-image";

const ContactUs = () => {
	const data = useStaticQuery(
		graphql`
			{
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "contact-us" }) {
					seoFieldGroups {
						localBusinessSchema
						metaDescription
						metaTitle
						openGraphDescription
						openGraphTitle
						productSchema
						image {
							altText
							publicUrl
							localFile {
								childImageSharp {
									gatsbyImageData(
										formats: [AUTO, WEBP]
										quality: 100
										transformOptions: { cropFocus: CENTER, fit: CONTAIN }
										layout: CONSTRAINED
										placeholder: BLURRED
									)
									original {
										width
										height
									}
								}
							}
						}
					}
					contactUsPageFieldGroups {
						contactBannerSection {
							heading
							description
							cta1 {
								target
								title
								url
							}
							cta2 {
								target
								title
								url
							}
							backgroundImage {
								altText
								localFile {
									publicURL
									childImageSharp {
										original {
											height
											width
										}
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
									}
								}
							}
						}
						contactGoogleMapSection {
							googleMap
							heading
						}
						contactRequestACallBackSection {
							formBtnLabel
							heading
						}
						contactPageSection {
							contactDetails {
								heading
								email {
									target
									title
									url
								}
								phone1 {
									target
									title
									url
								}
								phone2 {
									target
									title
									url
								}
								socialIcon {
									image {
										altText
										localFile {
											publicURL
											childImageSharp {
												original {
													height
													width
												}
												gatsbyImageData(
													formats: [AUTO, WEBP]
													quality: 100
													transformOptions: { cropFocus: CENTER, fit: CONTAIN }
													layout: CONSTRAINED
													placeholder: BLURRED
												)
											}
										}
									}
									link {
										target
										title
										url
									}
								}
							}
							businessAddress {
								address
								heading
								addressLink
							}
						}
					}
				}
			}
		`
	);
	const ref = useRef();

	const {
		pageData: { seoFieldGroups, contactUsPageFieldGroups },
		site,
	} = data;

	const siteUrl = site.siteMetadata.siteUrl;

	const {
		contactBannerSection,
		contactGoogleMapSection,
		contactPageSection,
		contactRequestACallBackSection,
	} = contactUsPageFieldGroups;

	const handleClick = () =>
		ref.current.scrollIntoView({
			behavior: "smooth",
			block: "start",
		});

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Seymour Locksmiths",
				item: {
					url: "https://www.seymour-locksmiths.co.uk",
					id: "https://www.seymour-locksmiths.co.uk",
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Contact Us",
				item: {
					url: "https://www.seymour-locksmiths.co.uk/contact-us",
					id: "https://www.seymour-locksmiths.co.uk/contact-us",
				},
			},
		],
	};

	return (
		<Layout>
			<CallIcon />
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			{/* <Helmet>
				<script
					src="https://www.google.com/recaptcha/api.js"
					async
					defer
				></script>
			</Helmet> */}
			<ContactPageSchema />
			<GatsbySeo
				title={seoFieldGroups?.metaTitle}
				description={seoFieldGroups?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/contact-us`,
					title: `${seoFieldGroups?.openGraphTitle}`,
					description: `${seoFieldGroups?.openGraphDescription}`,
					images: [
						{
							url: `${seoFieldGroups?.image?.localFile.publicURL}`,
							width: `${seoFieldGroups?.image?.localFile.childImageSharp.original.width}`,
							height: `${seoFieldGroups?.image?.localFile.childImageSharp.original.height}`,
							alt: `${seoFieldGroups?.image?.altText}`,
						},
					],
				}}
				twitter={{
					handle: "@jefflocksmith",
					site: "@jefflocksmith",
					cardType: "summary",
				}}
			/>

			{contactBannerSection &&
				!checkPropertiesForNull(contactBannerSection, ["heading"]) && (
					<Hero
						Tag="section"
						text={
							<SafeHtmlParser htmlContent={contactBannerSection?.description} />
						}
						image={
							contactBannerSection?.backgroundImage.localFile.childImageSharp
								.gatsbyImageData
						}
						heading={contactBannerSection?.heading}
						hFontSize="text-4xl"
						button={contactBannerSection?.cta1}
						button2={contactBannerSection?.cta2}
					/>
				)}

			<section className="pt-24" id="form">
				<Container fluid={true}>
					<Row className="justify-content-center">
						<Col xs={11} sm={10}>
							{contactRequestACallBackSection &&
								!checkPropertiesForNull(contactRequestACallBackSection, [
									"heading",
								]) && (
									<>
										<Row className="mb-20">
											<Col className="d-flex justify-content-center" xs={12}>
												<h2
													id="contact-form"
													className="text-3xl mb-3 fw-700 lato text-center"
													ref={ref}
												>
													{contactRequestACallBackSection?.heading}
												</h2>
											</Col>
											<Col className="d-flex justify-content-center" xs={12}>
												<span className="sl-underline d-block" />
											</Col>
										</Row>

										<Row className="justify-content-center mb-24">
											<Col xl={9}>
												<ContactForm
													btnText={contactRequestACallBackSection?.formBtnLabel}
												/>
											</Col>
										</Row>
									</>
								)}
							<Row>
								{contactPageSection &&
									!checkPropertiesForNull(contactPageSection, [
										"contactDetails",
									]) && (
										<Col xs={12} xl={6}>
											<Row className="mb-5">
												<Col className="d-flex justify-content-center" xs={12}>
													<h2 className="text-3xl mb-3 fw-700 lato text-center">
														{contactPageSection?.contactDetails.heading}
													</h2>
												</Col>
												<Col
													className="d-flex justify-content-center mb-4"
													xs={12}
												>
													<span className="sl-underline d-block" />
												</Col>
												{contactPageSection?.contactDetails.phone1 && (
													<Col xs={12} className="text-2xl mb-3 text-center">
														<a
															href={
																contactPageSection?.contactDetails.phone1?.url
															}
															className="text-decoration-none text-secondary"
														>
															{contactPageSection?.contactDetails.phone1?.title}
														</a>
													</Col>
												)}
												{contactPageSection?.contactDetails.phone2 && (
													<Col xs={12} className="text-2xl mb-3 text-center">
														<a
															href={
																contactPageSection?.contactDetails.phone2?.url
															}
															className="text-decoration-none text-secondary"
														>
															{contactPageSection?.contactDetails.phone2?.title}
														</a>
													</Col>
												)}
												{contactPageSection?.contactDetails.email && (
													<Col xs={12} className="text-xl mb-3 text-center">
														<a
															href={
																contactPageSection?.contactDetails.email?.url
															}
															className="text-decoration-none text-secondary"
														>
															{contactPageSection?.contactDetails.email?.title}
														</a>
													</Col>
												)}
											</Row>
											{contactPageSection.contactDetails.socialIcon &&
												contactPageSection.contactDetails.socialIcon.length >
													0 && (
													<Row className="justify-content-center mb-5">
														<Col className="d-flex text-center">
															<div className="d-flex mx-auto">
																{contactPageSection.contactDetails.socialIcon.map(
																	(item) => (
																		<a
																			className="social-icon"
																			target="_blank"
																			rel="noreferrer"
																			href={item.link?.url}
																		>
																			<GatsbyImage
																				image={
																					item?.image?.localFile.childImageSharp
																						.gatsbyImageData
																				}
																				alt={item?.image?.altText}
																				className="social-icons text-black"
																			/>
																		</a>
																	)
																)}
															</div>
														</Col>
													</Row>
												)}
										</Col>
									)}
								{contactPageSection &&
									!checkPropertiesForNull(contactPageSection, [
										"businessAddress",
									]) && (
										<Col xs={12} xl={6}>
											<Row className="mb-5">
												<Col className="d-flex justify-content-center" xs={12}>
													<h2 className="text-3xl mb-3 fw-700 lato text-center">
														{contactPageSection?.businessAddress?.heading}
													</h2>
												</Col>
												<Col
													className="d-flex justify-content-center mb-4"
													xs={12}
												>
													<span className="sl-underline d-block" />
												</Col>
												<a
													href={contactPageSection?.businessAddress.addressLink}
													target="_blank"
													rel="noreferrer"
													className="text-center text-decoration-none text-secondary text-sm-start mb-3 hover-no-decoration"
												>
													<SafeHtmlParser
														htmlContent={
															contactPageSection?.businessAddress?.address
														}
													/>
												</a>
											</Row>
										</Col>
									)}
							</Row>
						</Col>
					</Row>
				</Container>
			</section>

			{contactGoogleMapSection &&
				!checkPropertiesForNull(contactGoogleMapSection, ["heading"]) && (
					<AreaMap
						heading={contactGoogleMapSection?.heading}
						map={contactGoogleMapSection?.googleMap}
					/>
				)}
		</Layout>
	);
};

export default ContactUs;
